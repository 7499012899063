// vue
import { h, ref, reactive, computed, watch, onMounted } from 'vue';

// services
import VolatilityService from '@services/volatility';
import ExchangesService from '@services/exchangesService';

// i18n
import { useI18n } from 'vue-i18n';

// LocalStorage
import LocalStorage from '@services/ts/localStorage';

// shared
import { CRUD_PAGER_FACTORY } from '@shared/factories';

// store
import { useGl } from '@store/ts/gl';
import { useRefs } from '@store/ts/refs';
import { useTools } from '@store/tools';
import { useVolatility } from '@store/volatility';

// router
import { useRoute, useRouter } from 'vue-router';

// UI
import {
    useMessage } from 'naive-ui';

export default function () {
    // store
    const gl = useGl();
    const refs = useRefs();
    const tools = useTools();
    const volatility = useVolatility();

    // ui
    

    // i18n
    const { t } = useI18n();

    // vars
    const { _ } = window;
    const init = ref(false);
    const markers = ref();

    // storage filters
    const storeFilterKey = '___filters_volatility-indicators';
    let storeFilters = LocalStorage.getItem(storeFilterKey);

    // set filter settings
    if (storeFilters == null) {
        LocalStorage.setItem(storeFilterKey, CRUD_PAGER_FACTORY({
            exchanges: [5],
            markets: ['btc'],
        }));

        storeFilters = LocalStorage.getItem(storeFilterKey);
    }

    // filters
    const filters = reactive({
        ...storeFilters,
    });

    const resetFilters = () => {
        filters.filters.exchanges = [ -1 ];
        filters.filters.markets = [ -1 ];

        volatilityGet();
    };

    const filtersChanged = computed(() => {
        return filters.filters.exchanges[0] !== -1
          || filters.filters.markets[0] !== -1;
    });

    const refFilters = computed(() => [
        {
            mode: 'exchanges',
            title: refs.localization.filters.tools.filters_tools_exchanges_label,
            refs: refs.exchanges,
            filter: 'filters.exchanges',
            seeable: filters.filters.exchanges[0] !== -1,
        }, {
            mode: 'filter_coins',
            title: refs.localization.filters.tools.filters_tools_currencies_label,
            refs: markers.value,
            value: filters.filters.markets,
            filter: 'filters.markets',
            seeable: filters.filters.markets[0] !== -1,
        },
    ]);
  
    const getRefs = async (showLoading = true) => {
        if (showLoading)
            gl.showLoading = true;

        try {
            volatility.refs = ( await VolatilityService.getRefs() ).data;
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };
      
        if (showLoading)
            gl.showLoading = false;
    };

    const getMarkets = async () => {
        try {
            const result = ( await ExchangesService.getMarkets({
                ids: filters.filters.exchanges,
            }) ).data;

            if (result?.errors) {
                result.errors.forEach(({ msg }) => gl.showNotification({
                    type: 'error',
                    msg,
                }));
            } else {
                markers.value = result.markets.map(el => currency(el));
            }
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };
    };

    const getData = async () => {
        gl.showLoading = true;

        await Promise.allSettled([
            getRefs(false),
            getMarkets(),
            volatilityGet(false),
        ]);

        gl.showLoading = false;
    };

    const getMarkersAndVolatility = async () => {
        gl.showLoading = true;

        await Promise.allSettled([
            getMarkets(),
            volatilityGet(false),
        ]);

        gl.showLoading = false;
    };

    const volatilityGet = async (showLoading = true) => {
        if (showLoading)
            gl.showLoading = true;

        // save filters
        LocalStorage.setItem(storeFilterKey, filters);
        
        try {
            const prepare = await VolatilityService.get({
                pager: filters,
            });

            if (volatility.dataTable) {
                Object.keys(prepare.data).forEach(el => {
                    if(!['columns'].includes(el)) {
                        volatility.dataTable[el] = prepare.data[el];
                    }
                });
            } else {
                volatility.dataTable = prepare.data;
            }
        } catch {
            gl.showNotification({
                type: 'error',
                msg: t('errorMessage'),
            });
        };

        if (showLoading)
            gl.showLoading = false;
    };

    const changeFilter = ([ key, value ], update = false) => {
        if (key === 'perPage') {
            _.set(filters, 'page', 1);
        };

        _.set(filters, key, value !== null && value !== undefined ? value : [ -1 ]);

        if (update) {
            if (key.split('.')[1] === 'exchanges') {
                getMarkersAndVolatility();
            } else {
                volatilityGet();
            }
        }
    };

    const currency = icon => refs.currencies.find(el => el.id === icon);

    onMounted(async () => {
        await getData();

        init.value = true;
    });

    return {
        gl,
        refs,
        init,
        tools,
        filters,
        markers,
        volatility,
        refFilters,
        filtersChanged,
        resetFilters,
        changeFilter,
        volatilityGet,
    };
}